
import React from 'react';
import Footer from '../Footer'
import Header from '../Header'
import ImageGallery from '../ImageGallery'

import {
  BrowserRouter as Router,
} from 'react-router-dom'

import {withRouter} from 'react-router-dom'

class CrosswordDecipher extends React.Component {
  constructor() {
    super();

    this.state = {
      routerValue: Router,
      imageList: ["Modern-Sudoku1","Modern-Sudoku2","Modern-Sudoku3","Modern-Sudoku4"],
      OSName: 'iOS/',
    };
  }

  iOSon() {
    this.setState({ OSName: 'iOS/' })
  }

  iPadOSon() {
    this.setState({ OSName: 'iPadOS/' })
  }

  render() {
    var OSSelectionButton = [];
    if (this.state.OSName === 'iOS/') {
      OSSelectionButton.push(
        <div className="Toggle-Div">
          <button className="ToggleOS" onClick={() => {this.iPadOSon()}}>View iPadOS</button>
        </div>
      )
    } else {
      OSSelectionButton.push(
        <div className="Toggle-Div">
          <button className="ToggleOS" onClick={() => {this.iOSon()}}>View iOS</button>
        </div>
      )
    }

    return (
      <div className="Page-Division">
        <Header/>
        <div className="Page-Inside-Clear">
          <div className="App-Page-Top">
            <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/iOS/Modern-Sudoku.png'} alt=""/>
            <p className="App-Page-Title">Modern Sudoku</p>
          </div>
          <a className="Download-Icon-Link" href="https://apps.apple.com/app/id1604133932">
            <img className="Download-Icon" src={process.env.PUBLIC_URL + '/DownloadBadges/Download_iOS-Black.png'} alt=""/>
          </a>
          <br></br>
          <br></br>
          {OSSelectionButton}
          <ImageGallery
          imageList = {this.state.imageList}
          OS = {this.state.OSName}
          folder = {"Modern-Sudoku/"}
          />
          <div className="App-Description">
            <p>
              Sudoku is a logic based brain training number placement puzzle game, available for free with thousands of levels and 6 difficulties from beginner to master.
              <br></br>
              <br></br>
              The main objective the game is to fill a 9 * 9 grid with digits so that each column, each row, and each of the nine 3 * 3 subgrids that compose the grid contain all of the digits from 1 to 9.
              <br></br>
              <br></br>
              <br></br>
              Features:
            </p>
            <ul>
              <li>Disable sleep mode to focus on puzzle and prevent device going to sleep</li>
              <li>Notes mode to place multiple numbers in a block</li>
              <li>Highlight similar numbers in puzzle</li>
              <li>Highlight tiles mode</li>
              <li>Multiple themes including background colors, app icons, and fonts</li>
              <li>Light and dark mode support</li>
              <li>Game auto saves while playing</li>
              <li>Statistics and Game Center leaderboards</li>
            </ul>
            <br></br>
            <p>Available Difficulties:</p>
            <ul>
              <li>Beginner</li>
              <li>Easy</li>
              <li>Medium</li>
              <li>Hard</li>
              <li>Expert</li>
              <li>Master</li>
            </ul>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}



export default withRouter(CrosswordDecipher);
