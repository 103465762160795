
import React from 'react';
import Footer from '..//Footer'
import Header from '..//Header'
import ImageGallery from '..//ImageGallery'

import {
  BrowserRouter as Router,
} from 'react-router-dom'

import {withRouter} from 'react-router-dom'

class CrosswordDecipher extends React.Component {
  constructor() {
    super();

    this.state = {
      routerValue: Router,
      imageList: ["Crossword-Decipher1","Crossword-Decipher2","Crossword-Decipher3","Crossword-Decipher4","Crossword-Decipher5","Crossword-Decipher6"],
      OSName: 'iOS/',
    };
  }

  iOSon() {
    this.setState({ OSName: 'iOS/' })
  }

  iPadOSon() {
    this.setState({ OSName: 'iPadOS/' })
  }

  render() {
    var OSSelectionButton = [];
    if (this.state.OSName === 'iOS/') {
      OSSelectionButton.push(
        <div className="Toggle-Div">
          <button className="ToggleOS" onClick={() => {this.iPadOSon()}}>View iPadOS</button>
        </div>
      )
    } else {
      OSSelectionButton.push(
        <div className="Toggle-Div">
          <button className="ToggleOS" onClick={() => {this.iOSon()}}>View iOS</button>
        </div>
      )
    }

    return (
      <div className="Page-Division">
        <Header/>
        <div className="Page-Inside-Clear">
          <div className="App-Page-Top">
            <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/iOS/Crossword-Decipher.png'} alt=""/>
            <p className="App-Page-Title">Crossword Decipher</p>
          </div>
          <a className="Download-Icon-Link" href="https://apps.apple.com/app/cipher/id1470579852">
            <img className="Download-Icon" src={process.env.PUBLIC_URL + '/DownloadBadges/Download_iOS-Black.png'} alt=""/>
          </a>
          <br></br>
          <br></br>
          {OSSelectionButton}
          <ImageGallery
          imageList = {this.state.imageList}
          OS = {this.state.OSName}
          folder = {"Crossword-Decipher/"}
          />
          <div className="App-Description">
            <p>Crossword Decipher is a type of puzzle that has no clues, instead you have to solve the puzzle by solving the code words provided.
              <br></br>Currently there are 2 chapters in this game and each chapter contains 180 levels.
              <br></br>Each chapter is playable in 2 modes Relax and Challenge, and contains different puzzles in each mode. 
              <br></br>Chapter 1 - Letter Numbers:
              <br></br>The Letter-to-Number Cipher consists in replacing each letter by its position in the alphabet for example A=1, B=2, Z=26
              <br></br>Chapter 2  - Rot-13 Cipher: 
              <br></br>A simple letter substitution cipher that replaces a letter with the 13th letter after it  in the alphabet for example A=N, B=O,Z=M.
              <br></br><br></br>There are 2 playing modes:
              <br></br>Relax play: keys are provided for every letter in the puzzle and all you have to do is substitute it with the right letter.
              <br></br>Challenge play: keys are only provided for parts of the word and you have to complete the rest of them on your own</p>
            <ul>
              <li>Complete levels to unlock achievements</li>
              <li>Collect stars by completing levels without mistakes</li>
              <li>Have fun, keep your brain fit and Improve your literacy and logic skills</li>
            </ul>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}



export default withRouter(CrosswordDecipher);
