
import React from 'react';
import Footer from '..//Footer'
import Header from '..//Header'
import ImageGallery from '..//ImageGallery'

import {
  BrowserRouter as Router,
} from 'react-router-dom'

import {withRouter} from 'react-router-dom'

class Finance extends React.Component {
  constructor() {
    super();

    this.state = {
      routerValue: Router,
      imageList: ["Finance-Calculator1","Finance-Calculator2","Finance-Calculator3","Finance-Calculator4"],
    };
  }

  render() {
    return (
      <div className="Page-Division">
        <Header/>
        <div className="Page-Inside-Clear">
          <div className="App-Page-Top">
            <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/macOS/Finance-Calculator.png'} alt=""/>
            <p className="App-Page-Title">Finance Calculator</p>
          </div>
          <a className="Download-Icon-Link" href="https://itunes.apple.com/app/id1130694845?mt=12">
            <img className="Download-Icon" src={process.env.PUBLIC_URL + '/DownloadBadges/Download_macOS-Black.png'} alt=""/>
          </a>
          <ImageGallery
          imageList = {this.state.imageList}
          OS = {"macOS/"}
          folder = {"Finance-Calculator/"}
          />
          <div className="App-Description">
            <p>Finance Calculator is an easy to use App that Contains 97 Financial Calculators Split into 5 Categories:</p>
            <br></br>
            <p>General Finance Includes 31 Calculators:</p>
            <ul>
              <li>Annuity - Future Value</li>
              <li>Annuity - Future Value w/ Continuous Compounding</li>
              <li>Annuity - (FV) Solve for n</li>
              <li>Annuity - Payment (PV)</li>
              <li>Annuity - Payment (FV)</li>
              <li>Annuity - (PV) Solve for n</li>
              <li>Annuity - Present Value</li>
              <li>Avg Collection Period</li>
              <li>Annuity - PV Factor</li>
              <li>Annuity Due - Present Value</li>
              <li>Annuity Due - Future Value</li>
              <li>Annuity Due Payment (PV)</li>
              <li>Annuity Due Payment (FV)</li>
              <li>Doubling Time</li>
              <li>Doubling Time - Cont. Compounding</li>
              <li>Doubling Time - Simple Interest</li>
              <li>Future Value</li>
              <li>FV - Continuous Compounding</li>
              <li>Future Value Factor</li>
              <li>Growing Annuity - Future Value</li>
              <li>Growing Annuity - Payment (PV)</li>
              <li>Growing Annuity - Payment (FV)</li>
              <li>Growing Annuity - Present Value</li>
              <li>Growing Perpetuity - Present Value</li>
              <li>Number of Periods - PV & FV</li>
              <li>Perpetuity</li>
              <li>Present Value</li>
              <li>PV - Continuous Compounding</li>
              <li>Present Value Factor</li>
              <li>Rule of 72</li>
              <li>Weighted Average</li>
            </ul>
            <br></br>
            <p>Banking Includes 11 Calculators:</p>
            <ul>
              <li>Annual Percentage Yield</li>
              <li>Balloon Loan - Payments</li>
              <li>Compound Interest</li>
              <li>Continuous Compounding</li>
              <li>Debt to Income Ratio (D/I)</li>
              <li>Loan - Balloon Balance</li>
              <li>Loan - Payment</li>
              <li>Loan - Remaining Balance</li>
              <li>Loan to Deposit Ratio</li>
              <li>Loan to Value (LTV)</li>
              <li>Simple Interest</li>
            </ul>
            <br></br>
            <p>Stocks/Bonds Includes 28 Calculators:</p>
            <ul>
              <li>Bid Ask Spread</li>
              <li>Bond Equivalent Yield</li>
              <li>Book Value per Share</li>
              <li>Capital Asset Pricing Model</li>
              <li>Capital Gains Yield</li>
              <li>Current Yield</li>
              <li>Diluted Earnings Per Share</li>
              <li>Dividend Payout Ratio</li>
              <li>Dividend Yield - Stock</li>
              <li>Dividends Per Share</li>
              <li>Earnings Per Share</li>
              <li>Equity Multiplier</li>
              <li>Estimated Earnings</li>
              <li>Geometric Mean Return</li>
              <li>Holding Period Return</li>
              <li>Net Asset Value</li>
              <li>Preferred Stock</li>
              <li>Price to Book Value</li>
              <li>Price to Earnings (P/E Ratio)</li>
              <li>Price to Sales (P/S Ratio)</li>
              <li>Risk Premium</li>
              <li>Stock - PV with Constant Growth</li>
              <li>Stock - PV with Zero Growth</li>
              <li>Tax Equivalent Yield</li>
              <li>Total Stock Return</li>
              <li>Yield to Maturity</li>
              <li>Zero Coupon Bond Value</li>
              <li>Zero Coupon Bond Yield</li>
            </ul>
            <br></br>
            <p>Corporate Finance Includes 25 Calculators:</p>
            <ul>
              <li>Asset to Sales Ratio</li>
              <li>Asset Turnover Ratio</li>
              <li>Avg Collection Period</li>
              <li>Contribution Margin</li>
              <li>Current Ratio</li>
              <li>Days in Inventory</li>
              <li>Debt Coverage Ratio</li>
              <li>Debt Ratio</li>
              <li>Debt to Equity Ratio</li>
              <li>Discounted Payback Period</li>
              <li>Equivalent Annual Annuity</li>
              <li>Free Cash Flow to Equity (FCFE)</li>
              <li>Free Cash Flow to Firm (FCFF)</li>
              <li>Interest Coverage Ratio</li>
              <li>Inventory Turnover Ratio</li>
              <li>Net Present Value</li>
              <li>Net Profit Margin</li>
              <li>Net Working Capital</li>
              <li>Payback Period</li>
              <li>Quick Ratio</li>
              <li>Receivables Turnover Ratio</li>
              <li>Retention Ratio</li>
              <li>Return on Assets (ROA)</li>
              <li>Return on Equity (ROE)</li>
              <li>Return on Investment</li>
            </ul>
            <br></br>
            <p>Financial Markets Includes 2 Calculators:</p>
            <ul>
              <li>Rate of Inflation</li>
              <li>Real Rate of Return</li>
            </ul>
            <br></br>
            <p>Finance Calculator also includes:</p>
            <ul>
              <li>Formula for Each Calculator</li>
              <li>Description for Each Calculator</li>
              <li>Copy Answer Button</li>
              <li>Clear Button</li>
            </ul>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}



export default withRouter(Finance);
