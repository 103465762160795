/*THis componenet renders vanilla header with logo and links 

Drop down menu for small screen devices uses this example
https://blog.campvanilla.com/reactjs-dropdown-menus-b6e06ae3a8fe
and is modified
*/

import React, { Component } from 'react';

import {
  BrowserRouter as Router,
} from 'react-router-dom'

import {withRouter} from 'react-router-dom'

import SearchFunction from './SearchFunction'


class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
      showSearch: false,
      routerValue : Router,
    };
    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
    this.showSearch = this.showSearch.bind(this);
    this.closeSearch = this.closeSearch.bind(this);
  }

  showMenu(event) {
    event.preventDefault();
    this.setState({ showMenu: true }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  }

  closeMenu(event) {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener('click', this.closeMenu);
    });
  }

  showSearch(event) {
    event.preventDefault();
    this.setState({ showSearch: true }, () => {
      document.addEventListener('click', this.closeSearch);
    });
  }

  closeSearch(event) {
    if (!this.dropdownMenu.contains(event.target)) {
      this.setState({ showSearch: false }, () => {
        document.removeEventListener('click', this.closeSearch);
      });
    }
  }

  render() {
    return (
      <div>
      <header className="App-header">
        <button
          className="Header-button-menu"
          onClick={this.showMenu}
          style={{ backgroundImage: "url(Images/menu-icon.png)", backgroundRepeat: 'no-repeat' }}>
        </button>
        <button className="Header-button" onClick={() => {this.props.history.replace('/#home')}}>Home</button>
        <button className="Header-button" onClick={() => {this.props.history.replace("/#macos/apps")}}>macOS Apps</button>
        <button className="Header-button" onClick={() => {this.props.history.replace("/#ios/apps")}}>iOS and iPadOS Apps</button>
        <button className="Header-button" onClick={() => {this.props.history.replace("/#privacy-policy")}}>Privacy Policy</button>
        <button className="Header-button" onClick={() => {this.props.history.replace("/#contact")}}>Contact</button>

        {
          this.state.showMenu
            ? (
              <div className="headerMenu" ref={(element) => { this.dropdownMenu = element; }}>
                <button className="headerMenuItem" onClick={() => {this.props.history.replace('/#home')}}>Home</button>
                <button className="headerMenuItem" onClick={() => {this.props.history.replace("/#macos/apps")}}>macOS Apps</button>
                <button className="headerMenuItem" onClick={() => {this.props.history.replace("/#ios/apps")}}>iOS and iPadOS Apps</button>
                <button className="headerMenuItem" onClick={() => {this.props.history.replace("/#privacy-policy")}}>Privacy Policy</button>
                <button className="headerMenuItem" onClick={() => {this.props.history.replace("/#contact")}}>Contact</button>
              </div>
              )
            : (null)
        }
        <button
          className="Header-button-search"
          onClick={this.showSearch}
          style={{ backgroundImage: "url(Images/search-icon.png)", backgroundRepeat: 'no-repeat' }}>
        </button>
      </header>
      <div className="App-Search">
      {
        this.state.showSearch
          ? (
            <div className="searchMenu" ref={(element) => { this.dropdownMenu = element; }}>
              <SearchFunction/>
            </div>
          )
        : (null)
      }
      </div>
      </div>
    );
  }
}



export default withRouter(Header)