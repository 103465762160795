
import React from 'react';
import Footer from '..//Footer'
import Header from '..//Header'
import ImageGallery from '..//ImageGallery'

import {
  BrowserRouter as Router,
} from 'react-router-dom'

import {withRouter} from 'react-router-dom'

class CalcVerter extends React.Component {
  constructor() {
    super();

    this.state = {
      routerValue: Router,
      imageList: ["CalcVerter1","CalcVerter2","CalcVerter3","CalcVerter4","CalcVerter5","CalcVerter6","CalcVerter7","CalcVerter8","CalcVerter9","CalcVerter10","CalcVerter11","CalcVerter12"],
    };
  }

  render() {
    return (
      <div className="Page-Division">
        <Header/>
        <div className="Page-Inside-Clear">
          <div className="App-Page-Top">
            <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/macOS/CalcVerter.png'} alt=""/>
            <p className="App-Page-Title">CalcVerter</p>
          </div>
          <a className="Download-Icon-Link" href="https://itunes.apple.com/app/id923932984?mt=12">
            <img className="Download-Icon" src={process.env.PUBLIC_URL + '/DownloadBadges/Download_macOS-Black.png'} alt=""/>
          </a>
          <ImageGallery
          imageList = {this.state.imageList}
          OS = {"macOS/"}
          folder = {"CalcVerter/"}
          />
          <div className="App-Description">
            <p>CalcVerter is an all in one Unit Converter and Calculator app that includes 22 Unit Conversion Categories, Scientific Calculator with optional RPN mode, and many more Calculators</p>
            <p>Here is what’s included in CalcVerter</p>
            <ul>
              <li>22 Unit Conversion Categories including: Acceleration, Angle, Area, Astronomical, Currency, Density, Digital Storage, Energy, Force, Illumination, Inductance, Length, Magnitude, Mass, Power, Pressure, Speed, Temperature, Time and Volume.</li>
              <li>Calculator with optional RPN Mode, Integrated with the Unit Converter, Constants, and all the other tools that are included in CalcVerter</li>
              <li>30 Geometry Calculators split into 4 categories: Area, Surface Area, Volume and Perimeter, including formulas and descriptions</li>
              <li>97 Financial Calculators split into 5 Categories: General, Banking, Stocks/Bonds, Corporate and Financial Markets, including formulas and descriptions</li>
              <li>120 Physics Calculators including formulas and descriptions</li>
              <li>59 Engineering Calculators split into 3 categories: Civil, Electrical and Mechanical including formulas and descriptions</li>
              <li>34 Health Calculators including formulas and descriptions</li>
              <li>78 Dynamics Calculators split into 3 categories: Basic Dynamics, Astrodynamics and Thermodynamics including formulas and descriptions</li>
              <li>35 Chemistry Calculators including formulas and descriptions</li>
              <li>90 Fluid Mechanics Calculators including formulas and descriptions</li>
              <li>5 Quantum Calculators including formulas and descriptions</li>
              <li>Convert Between bases 2-64</li>
              <li>Over 50 Constants</li>
              <li>Find weight on other Planets and celestial objects</li>
              <li>Table of Elements</li>
              <li>Create, share and import user calculators</li>
              <li>Create, share and import user constants</li>
            </ul>
            <br></br>
            <p>Calculator features:</p>
            <ul>
            <li>Multiple calculators with unique registers</li>
            <li>Selection of calculator layouts including Basic, Programming, Engineering</li>
            <li>Calculator tape with recent calculations and convertions</li>
            <li>Set a theme for every calculator</li>
            <li>View calculator registers</li>
            <li>Undo and Redo calculations</li>
            </ul>
            <br></br>
            <p>Please feel free to send any suggestions, report errors or bugs to the support email below to make CalcVerter even better and more useful.</p>
            <br></br>
            <br></br>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}



export default withRouter(CalcVerter);
