
import React from 'react';
import Footer from '..//Footer'
import Header from '..//Header'
import ImageGallery from '..//ImageGallery'

import {
  BrowserRouter as Router,
} from 'react-router-dom'

import {withRouter} from 'react-router-dom'

class Cipher extends React.Component {
  constructor() {
    super();

    this.state = {
      routerValue: Router,
      imageList: ["Cipher1","Cipher2","Cipher3"],
    };
  }

  render() {
    return (
      <div className="Page-Division">
        <Header/>
        <div className="Page-Inside-Clear">
          <div className="App-Page-Top">
            <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/macOS/Cipher.png'} alt=""/>
            <p className="App-Page-Title">Cipher</p>
          </div>
          <a className="Download-Icon-Link" href="https://apps.apple.com/app/id1468668353">
            <img className="Download-Icon" src={process.env.PUBLIC_URL + '/DownloadBadges/Download_macOS-Black.png'} alt=""/>
          </a>
          <ImageGallery
          imageList = {this.state.imageList}
          OS = {"macOS/"}
          folder = {"Cipher/"}
          />
          <div className="App-Description">
            <p>Cipher is a simple and easy to use App allows you to encrypt and decrypt text.</p>
            <br></br>
            <p>To encrypt text:</p>
            <ol>
              <li>Type in the text you wish to encrypt or click the paste button</li>
              <li>Select your encryption method</li>
              <li>Click encrypt button</li>
              <li>Click the copy button to copy the output</li>
            </ol>
            <p>To decrypt text:</p>
            <ol>
              <li>Type in the text you wish to decrypt or click the paste button</li>
              <li>Select your encryption method</li>
              <li>Click decrypt button</li>
              <li>Click the copy button to copy the output</li>
            </ol>
            <p>Encryption methods available:</p>
            <ul>
              <li>ROT-X Cipher</li>
              <li>Modified ROT Cipher</li>
              <li>Vigenere Cipher</li>
              <li>Letter Numbers Cipher</li>
            </ul>
            <p>More encryption methods will be added in the future.</p>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}



export default withRouter(Cipher);
