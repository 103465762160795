
import React from 'react';
import Footer from '..//Footer'
import Header from '..//Header'
import ImageGallery from '..//ImageGallery'

import {
  BrowserRouter as Router,
} from 'react-router-dom'

import {withRouter} from 'react-router-dom'
import { SegmentedControl } from 'segmented-control'

class Tetris extends React.Component {
  constructor() {
    super();

    this.state = {
      routerValue: Router,
      imageList: ["Falling-Blocks1","Falling-Blocks2","Falling-Blocks3","Falling-Blocks4","Falling-Blocks5","Falling-Blocks6"],
      OSName: 'iOS/',
    };
  }

  iOSon() {
    this.setState({ OSName: 'iOS/' })
  }

  iPadOSon() {
    this.setState({ OSName: 'iPadOS/' })
  }

  tvOSon() {
    this.setState({ OSName: 'tvOS/' })
  }

  selectOS(value) {
    if (value === "ios") {
      this.setState({ OSName: 'iOS/' })
    } else if (value === "ipados") {
      this.setState({ OSName: 'iPadOS/' })
    } else if (value === "tvos") {
      this.setState({ OSName: 'tvOS/' })
    }
  }

  render() {
    var OSSelectionButton = [];
    if (this.state.OSName === 'iOS/') {
      OSSelectionButton.push(
        <div className="Toggle-Div">
          <button className="ToggleOS" onClick={() => {this.iPadOSon()}}>View iPadOS</button>
        </div>
      )
    } else if (this.state.OSName === 'iPadOS/') {
      OSSelectionButton.push(
        <div className="Toggle-Div">
          <button className="ToggleOS" onClick={() => {this.tvOSon()}}>View tvOS</button>
        </div>
      ) 
      } else {
        OSSelectionButton.push(
          <div className="Toggle-Div">
            <button className="ToggleOS" onClick={() => {this.iOSon()}}>View iOS</button>
          </div>
        )
    }
    return (
      <div className="Page-Division">
        <Header/>
        <div className="Page-Inside-Clear">
          <div className="App-Page-Top">
            <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/iOS/Tetris.png'} alt=""/>
            <p className="App-Page-Title">Falling Blocks</p>
          </div>
          <a className="Download-Icon-Link" href="https://apps.apple.com/app/tetris/id1474442643">
            <img className="Download-Icon" src={process.env.PUBLIC_URL + '/DownloadBadges/Download_iOS-Black.png'} alt=""/>
          </a>
          <br></br>
          <br></br>
          <div className="Toggle-Div">
            <SegmentedControl
            name="osSegment"
            className="osSegment"
            options={[
              { label: "iOS", value: "ios", default: true },
              { label: "iPadOS", value: "ipados" },
              { label: "tvOS", value: "tvos"},
            ]}
            setValue={newValue => this.selectOS(newValue)}
            style={{width: 250, color: '#303030'}}
            />
          </div>
          
          <ImageGallery
          imageList = {this.state.imageList}
          OS = {this.state.OSName}
          folder = {"Falling-Blocks/"}
          />
          <div className="App-Description">
            <p>Falling Blocks is a very addictive game that helps you exercise your brain and improve you logic skills.</p>
              <br></br>
              <p>How to play:</p>
              <ul>
                <li>Swipe left and right to move the blocks</li>
                <li>Swipe up to rotate the blocks </li>
                <li>Swipe down to slam the blocks into place or wait for them to slowly fall into place</li>
                <li>Form a line horizontally to clear out the blocks</li>
                <li>There is no need to worry about matching colours</li>
                <li>Just try and beat your high score and challenge your friends on Game Center</li>
              </ul>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}



export default withRouter(Tetris);
