
import React from 'react';
import Footer from '..//Footer'
import Header from '..//Header'
import ImageGallery from '..//ImageGallery'

import {
  BrowserRouter as Router,
} from 'react-router-dom'

import {withRouter} from 'react-router-dom'

class QuickNotes extends React.Component {
  constructor() {
    super();

    this.state = {
      routerValue: Router,
      imageList: ["Quick-Notes1","Quick-Notes2","Quick-Notes3"],
    };
  }

  render() {
    return (
      <div className="Page-Division">
        <Header/>
        <div className="Page-Inside-Clear">
          <div className="App-Page-Top">
            <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/macOS/Quick-Notes.png'} alt=""/>
            <p className="App-Page-Title">Quick Notes</p>
          </div>
          <a className="Download-Icon-Link" href="https://itunes.apple.com/app/id1260480179?mt=12">
            <img className="Download-Icon" src={process.env.PUBLIC_URL + '/DownloadBadges/Download_macOS-Black.png'} alt=""/>
          </a>
          <ImageGallery
          imageList = {this.state.imageList}
          OS = {"macOS/"}
          folder = {"Quick-Notes/"}
          />
          <div className="App-Description">
            <p>Quick Notes is a simple note taking App and Today View widget.</p>
            <p>Features:</p>
            <ul>
              <li>15 Notes</li>
              <li>Today View Widget</li>
              <li>13 Font Colours</li>
              <li>27 Font Sizes</li>
              <li>Text Alignment options</li>
              <li>iCloud Sync</li>
              <li>App and Today Widget Synchronised together</li>
              <li>Different Colour for each note</li>
              <li>Widget expands when you run out of space and collapses back to original size when not being used</li>
              <li>Click the coloured squares in the today widget to open quick notes App.</li>
            </ul>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}



export default withRouter(QuickNotes);
