import React, { Component } from 'react';

import {
  BrowserRouter as Router,
} from 'react-router-dom'

import {withRouter} from 'react-router-dom'

class Footer extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        routerValue : Router,
      };
    }
  
    render() {
        return (
            <footer className="App-footer">
                <div className="Footer-Top">
                    <div className="Footer-Div">
                        <p className="Footer-Heading">macOS Apps:</p>
                        <p className="Footer-Link" onClick={() => {this.props.history.replace("/#macos/calcverter")}}>CalcVerter</p>
                        <p className="Footer-Link" onClick={() => {this.props.history.replace("/#macos/finance-calculator")}}>Finance Calculator</p>
                        <p className="Footer-Link" onClick={() => {this.props.history.replace("/#macos/health-calculator")}}>Health Calculator</p>
                        <p className="Footer-Link" onClick={() => {this.props.history.replace("/#macos/engineering-calculator")}}>Engineering Calculator</p>
                        <p className="Footer-Link" onClick={() => {this.props.history.replace("/#macos/geometry-calculator")}}>Geometry Calculator</p>
                        <p className="Footer-Link" onClick={() => {this.props.history.replace("/#macos/dynamics-calculator")}}>Dynamics Calculator</p>
                        <p className="Footer-Link" onClick={() => {this.props.history.replace("/#macos/physics-calculator")}}>Physics Calculator</p>
                        <p className="Footer-Link" onClick={() => {this.props.history.replace("/#macos/quick-notes")}}>Quick Notes</p>
                        <p className="Footer-Link" onClick={() => {this.props.history.replace("/#macos/apps")}}>More Apps for macOS</p>
                    </div>
                    <div className="Footer-Div">
                        <p className="Footer-Heading">iOS Apps:</p>
                        <p className="Footer-Link" onClick={() => {this.props.history.replace("/#ios/calcverter")}}>CalcVerter</p>
                        <p className="Footer-Link" onClick={() => {this.props.history.replace("/#ios/finance-calculator")}}>Finance Calculator</p>
                        <p className="Footer-Link" onClick={() => {this.props.history.replace("/#ios/health-calculator")}}>Health Calculator</p>
                        <p className="Footer-Link" onClick={() => {this.props.history.replace("/#ios/engineering-calculator")}}>Engineering Calculator</p>
                        <p className="Footer-Link" onClick={() => {this.props.history.replace("/#ios/geometry-calculator")}}>Geometry Calculator</p>
                        <p className="Footer-Link" onClick={() => {this.props.history.replace("/#ios/fluid-mechanics-calculator")}}>Fluid Mechanics Calculator</p>
                        <p className="Footer-Link" onClick={() => {this.props.history.replace("/#ios/physics-calculator")}}>Physics Calculator</p>
                        <p className="Footer-Link" onClick={() => {this.props.history.replace("/#ios/base-converter-pro")}}>Base Converter Pro</p>
                        <p className="Footer-Link" onClick={() => {this.props.history.replace("/#ios/apps")}}>More Apps for iOS</p>
                    </div>
                    <div className="Footer-Div">
                        <p className="Footer-Heading">About Loay Setrak:</p>
                        <p className="Footer-Link" onClick={() => {this.props.history.replace("/#contact")}}>Loay Setrak (Contact Page)</p>
                        <p className="Footer-Link">
                            <a className="Footer-Link-Anchor" href="mailto:loaysetrak.software@outlook.com.au">Loay Setrak (Contact Email)</a>
                        </p>
                    </div>
                </div>
                <br></br>
                <hr className="Footer-line"></hr>
                <div className="Footer-Bottom">
                    <p className="Copyright">Copyright © 2021 Loay Setrak. All rights reserved.</p>
                </div>
            </footer>
        );
    }
}

export default withRouter(Footer)
