
import React from 'react';
import Footer from '..//Footer'
import Header from '..//Header'
import ImageGallery from '..//ImageGallery'

import {
  BrowserRouter as Router,
} from 'react-router-dom'

import {withRouter} from 'react-router-dom'

class BaseConverterPro extends React.Component {
  constructor() {
    super();

    this.state = {
      routerValue: Router,
      imageList: ["Base-Converter-Pro1","Base-Converter-Pro2","Base-Converter-Pro3","Base-Converter-Pro4"],
    };
  }

  render() {
    return (
      <div className="Page-Division">
        <Header/>
        <div className="Page-Inside-Clear">
          <div className="App-Page-Top">
            <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/macOS/Base-Converter-Pro.png'} alt=""/>
            <p className="App-Page-Title">Base Converter Pro</p>
          </div>
          <a className="Download-Icon-Link" href="https://itunes.apple.com/app/id939169935?mt=12">
            <img className="Download-Icon" src={process.env.PUBLIC_URL + '/DownloadBadges/Download_macOS-Black.png'} alt=""/>
          </a>
          <ImageGallery
          imageList = {this.state.imageList}
          OS = {"macOS/"}
          folder = {"Base-Converter-Pro/"}
          />
          <div className="App-Description">
            <p>Base Converter Pro can easily convert between bases 2 to 64, just tap on one of the fields and start entering values in and it will automatically convert to other bases.</p>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}



export default withRouter(BaseConverterPro);
