
import React from 'react';
import Footer from '..//Footer'
import Header from '..//Header'
import ImageGallery from '..//ImageGallery'

import {
  BrowserRouter as Router,
} from 'react-router-dom'

import {withRouter} from 'react-router-dom'

class StrongPasswordGenerator extends React.Component {
  constructor() {
    super();

    this.state = {
      routerValue: Router,
      imageList: ["Strong-Password-Generator1","Strong-Password-Generator2","Strong-Password-Generator3","Strong-Password-Generator4","Strong-Password-Generator5"],
      OSName: 'iOS/',
    };
  }

  iOSon() {
    this.setState({ OSName: 'iOS/' })
  }

  iPadOSon() {
    this.setState({ OSName: 'iPadOS/' })
  }

  render() {
    var OSSelectionButton = [];
    if (this.state.OSName === 'iOS/') {
      OSSelectionButton.push(
        <div className="Toggle-Div">
          <button className="ToggleOS" onClick={() => {this.iPadOSon()}}>View iPadOS</button>
        </div>
      )
    } else {
      OSSelectionButton.push(
        <div className="Toggle-Div">
          <button className="ToggleOS" onClick={() => {this.iOSon()}}>View iOS</button>
        </div>
      )
    }

    return (
      <div className="Page-Division">
        <Header/>
        <div className="Page-Inside-Clear">
          <div className="App-Page-Top">
            <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/iOS/Strong-Password-Generator.png'} alt=""/>
            <p className="App-Page-Title">Strong Password Generator</p>
          </div>
          <a className="Download-Icon-Link" href="https://apps.apple.com/app/id1468302178">
            <img className="Download-Icon" src={process.env.PUBLIC_URL + '/DownloadBadges/Download_iOS-Black.png'} alt=""/>
          </a>
          <br></br>
          <br></br>
          {OSSelectionButton}
          <ImageGallery
          imageList = {this.state.imageList}
          OS = {this.state.OSName}
          folder = {"Strong-Password-Generator/"}
          />
          <div className="App-Description">
            <p>Strong Password generator allow you to easily generate random passwords.</p>
            <p>Passwords can be one or a combination of some or all of the following:</p>
            <ul>
              <li>Numbers</li>
              <li>Symbols</li>
              <li>Lower case letters</li>
              <li>Upper case letters</li>
              <li>Text Alignment options</li>
            </ul>
            <br></br>
            <p>Features:</p>
            <ul>
              <li>Passwords can be from 4 - 64 characters long depending on use inputs</li>
              <li>Similar characters can be excluded (e.g. 1,i,I,l,L,o,O,0)</li>
              <li>Duplicate characters can be excluded</li>
              <li>Includes password strength indicator</li>
              <li>Saved password are stored securely using a master password</li>
              <li>Tap on generated password to copy</li>
            </ul>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}



export default withRouter(StrongPasswordGenerator);
