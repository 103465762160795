
import React from 'react';
import Footer from '..//Footer'
import Header from '..//Header'
import ImageGallery from '..//ImageGallery'

import {
  BrowserRouter as Router,
} from 'react-router-dom'

import {withRouter} from 'react-router-dom'

class Blocks100 extends React.Component {
  constructor() {
    super();

    this.state = {
      routerValue: Router,
      imageList: ["100-Blocks1","100-Blocks2","100-Blocks3","100-Blocks4","100-Blocks5","100-Blocks6"],
    };
  }

  render() {
    return (
      <div className="Page-Division">
        <Header/>
        <div className="Page-Inside-Clear">
          <div className="App-Page-Top">
            <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/iOS/100-Blocks.png'} alt=""/>
            <p className="App-Page-Title">100 Blocks - Challenge</p>
          </div>
          <a className="Download-Icon-Link" href="https://apps.apple.com/app/100-blocks-challenge/id1507391134">
            <img className="Download-Icon" src={process.env.PUBLIC_URL + '/DownloadBadges/Download_iOS-Black.png'} alt=""/>
          </a>
          <ImageGallery
          imageList = {this.state.imageList}
          OS = {"iOS/"}
          folder = {"100-Blocks/"}
          />
          <div className="App-Description">
          <p>100 blocks is a highly addictive brain training game that helps you improve your logic skills and keeps your brain healthy.</p>
          <p>Simply move the blocks into the 10x10 grid and clear the blocks by forming a line.</p>
          <p>Unlike other 1010 games out there, this game comes in 2 modes:</p>
          <p>Challenge mode: requires you to place blocks into the grid before the timer runs out, the timer will reset after every successful move you make. The game will end if you run out of time or if you have no more space on the board for the given blocks.</p>
          <p>Classic mode: allows you to play the classic 1010 without the time limit. the game will end when have no more space on the board for the given blocks</p>
          <p>Challenge mode gives you more points so you can achieve higher scores than you would playing classic mode.</p>
          <p>You can also challenge your friends on Game Center and see who can achieve the highest score.</p>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}



export default withRouter(Blocks100);
