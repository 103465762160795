
import React from 'react';
import Footer from '..//Footer'
import Header from '..//Header'
import ImageGallery from '..//ImageGallery'

import {
  BrowserRouter as Router,
} from 'react-router-dom'

import {withRouter} from 'react-router-dom'

class Speedy extends React.Component {
  constructor() {
    super();

    this.state = {
      routerValue: Router,
      imageList: ["Speedy1","Speedy2","Speedy3","Speedy4","Speedy5","Speedy6","Speedy7","Speedy8","Speedy9","Speedy10"],
      OSName: 'iOS/',
    };
  }

  iOSon() {
    this.setState({ OSName: 'iOS/' })
  }

  iPadOSon() {
    this.setState({ OSName: 'iPadOS/' })
  }

  render() {
    var OSSelectionButton = [];
    if (this.state.OSName === 'iOS/') {
      OSSelectionButton.push(
        <div className="Toggle-Div">
          <button className="ToggleOS" onClick={() => {this.iPadOSon()}}>View iPadOS</button>
        </div>
      )
    } else {
      OSSelectionButton.push(
        <div className="Toggle-Div">
          <button className="ToggleOS" onClick={() => {this.iOSon()}}>View iOS</button>
        </div>
      )
    }

    return (
      <div className="Page-Division">
        <Header/>
        <div className="Page-Inside-Clear">
          <div className="App-Page-Top">
            <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/iOS/Speedy.png'} alt=""/>
            <p className="App-Page-Title">Speedy</p>
          </div>
          <a className="Download-Icon-Link" href="https://apps.apple.com/app/speedy/id1472518879">
            <img className="Download-Icon" src={process.env.PUBLIC_URL + '/DownloadBadges/Download_iOS-Black.png'} alt=""/>
          </a>
          <br></br>
          <br></br>
          {OSSelectionButton}
          <ImageGallery
          imageList = {this.state.imageList}
          OS = {this.state.OSName}
          folder = {"Speedy/"}
          />
          <div className="App-Description">
            <p>Speedy is a Speed and reflex based game</p>
              <p>Test how fast you can:</p>
              <ul>
                <li>Tap the screen with one finger</li>
                <li>Swipe left and right on the screen</li>
                <li>Alternate tap between 2 fingers</li>
                <li>Pick the highlighted circles on the screen</li>
              </ul>
              <p>Simply keep tapping or swiping until the red line is pushed all the way to the end.</p>
              <p>Arcade includes:</p>
              <ul>
                <li>60 levels</li>
                <li>Push the red line all the way to the top to complete the challenge</li>
              </ul>
              <p>Endless:</p>
              <ul>
                <li>See how long you can last against the computer</li>
                <li>Speed increases gradually</li>
                <li>Try to beat the high scores</li>
              </ul>
              <p>Time trial:</p>
              <ul>
                <li>See how fast you can tap or swipe using a countdown of 10, 20 or 30 seconds</li>
              </ul>
              <p>2 Player:</p>
              <ul>
                <li>Challenge you friend and see who can tap or swipe faster</li>
              </ul>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}



export default withRouter(Speedy);
