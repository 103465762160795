
import React from 'react';
import Footer from '..//Footer'
import Header from '..//Header'
import ImageGallery from '..//ImageGallery'

import {
  BrowserRouter as Router,
} from 'react-router-dom'

import {withRouter} from 'react-router-dom'

class Cipher extends React.Component {
  constructor() {
    super();

    this.state = {
      routerValue: Router,
      imageList: ["Color-Code-Generator1","Color-Code-Generator2","Color-Code-Generator3","Color-Code-Generator4"],
      OSName: 'iOS/',
    };
  }

  iOSon() {
    this.setState({ OSName: 'iOS/' })
  }

  iPadOSon() {
    this.setState({ OSName: 'iPadOS/' })
  }

  render() {
    var OSSelectionButton = [];
    if (this.state.OSName === 'iOS/') {
      OSSelectionButton.push(
        <div className="Toggle-Div">
          <button className="ToggleOS" onClick={() => {this.iPadOSon()}}>View iPadOS</button>
        </div>
      )
    } else {
      OSSelectionButton.push(
        <div className="Toggle-Div">
          <button className="ToggleOS" onClick={() => {this.iOSon()}}>View iOS</button>
        </div>
      )
    }

    return (
      <div className="Page-Division">
        <Header/>
        <div className="Page-Inside-Clear">
          <div className="App-Page-Top">
            <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/iOS/Color-Code-Generator.png'} alt=""/>
            <p className="App-Page-Title">Color Code Generator</p>
          </div>
          <a className="Download-Icon-Link" href="https://apps.apple.com/app/id1478407640">
            <img className="Download-Icon" src={process.env.PUBLIC_URL + '/DownloadBadges/Download_iOS-Black.png'} alt=""/>
          </a>
          <br></br>
          <br></br>
          {OSSelectionButton}
          <ImageGallery
          imageList = {this.state.imageList}
          OS = {this.state.OSName}
          folder = {"Color-Code-Generator/"}
          />
          <div className="App-Description">
            <p>A must have simple tool for developers</p>
            <p>You can:</p>
            <ul>
              <li>Click on one of the provided colors</li>
              <li>Click on colour picker and chose a colour</li>
              <li>View colour details and codes</li>
              <li>Save your colour for later use</li>
              <li>Add optional title and description to each saved colour</li>
              <li>Create colour Collections for different projects</li>
            </ul>
            <p>Your color will instantly generate codes that can be used for app or web development</p>
            <p>Simply click generated code to copy it</p>
            <p>Code Formats:</p>
            <ul>
              <li>Swift (UIColor)</li>
              <li>Swift (NSColor)</li>
              <li>SwiftUI</li>
              <li>Objective-C</li>
              <li>Xamarin (C#)</li>
              <li>CSS (RGB)</li>
              <li>CSS (RGBA)</li>
            </ul>
            <br></br>
            <p>Color Details:</p>
            <ul>
              <li>RGBA</li>
              <li>HEX (RGB)</li>
              <li>HEX (RGBA)</li>
              <li>HSB</li>
              <li>HSL</li>
              <li>XYZ</li>
              <li>xyY</li>
              <li>Lab</li>
            </ul>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}

export default withRouter(Cipher);
