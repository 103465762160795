
import React from 'react';
import Footer from '..//Footer'
import Header from '..//Header'
import ImageGallery from '..//ImageGallery'

import {
  BrowserRouter as Router,
} from 'react-router-dom'

import {withRouter} from 'react-router-dom'

class FallingBlocks extends React.Component {
  constructor() {
    super();

    this.state = {
      routerValue: Router,
      imageList: ["Falling-Blocks1","Falling-Blocks2"],
    };
  }

  render() {
    return (
      <div className="Page-Division">
        <Header/>
        <div className="Page-Inside-Clear">
          <div className="App-Page-Top">
            <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/macOS/Falling-Blocks.png'} alt=""/>
            <p className="App-Page-Title">Falling Blocks</p>
          </div>
          <a className="Download-Icon-Link" href="https://apps.apple.com/app/id1474614556">
            <img className="Download-Icon" src={process.env.PUBLIC_URL + '/DownloadBadges/Download_macOS-Black.png'} alt=""/>
          </a>
          <ImageGallery
          imageList = {this.state.imageList}
          OS = {"macOS/"}
          folder = {"Falling-Blocks/"}
          />
          <div className="App-Description">
            <p>Falling Blocks is a very addictive game that helps you exercise your brain and improve you logic skills.</p>
            <br></br>
            <p>How to play:</p>
            <ul>
              <li>Move blocks left and right with arrow keys</li>
              <li>Use up key to rotate</li>
              <li>Use down key to slam the blocks into place or wait for them to slowly fall into place</li>
              <li>Form a line horizontally to clear out the blocks</li>
              <li>There is no need to worry about matching colours</li>
              <li>Just try and beat your high score and challenge your friends on Game Center</li>
            </ul>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}



export default withRouter(FallingBlocks);
