/*THis componenet renders vanilla header with logo and links 

Drop down menu for small screen devices uses this example
https://blog.campvanilla.com/reactjs-dropdown-menus-b6e06ae3a8fe
and is modified
*/

import React from 'react';

class ImageGallery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      enlargeImage: false,
      expandList: false,
      imageList: this.props.imageList,
      card_count: 0,
      clickedImage: "CalcVerter1",
    };
  }

  componentDidMount() {
    this.setState({ card_count: this.state.imageList.length})
  }

  ToggleenlargeImageOn() {
    this.setState({ enlargeImage: true })
  }

  ToggleenlargeImageOff() {
    this.setState({ enlargeImage: false })
  }

  ToggleexpandListOn() {
    this.setState({ expandList: true })
  }

  ToggleexpandListOff() {
    this.setState({ expandList: false })
  }

  ToggleNextImage() {
    let currentIndex = this.state.imageList.indexOf(this.state.clickedImage)
    if (currentIndex < this.state.card_count-1) {
      this.setState({ clickedImage: this.state.imageList[currentIndex+1]})
    } else {
      this.setState({ clickedImage: this.state.imageList[0]})
    }
  }

  TogglePreviousImage() {
    let currentIndex = this.state.imageList.indexOf(this.state.clickedImage)

    if (currentIndex === 0) {
      this.setState({ clickedImage: this.state.imageList[this.state.card_count-1]})
    } else {
      this.setState({ clickedImage: this.state.imageList[currentIndex-1]})
    }
  }

  render() {
    var divisions = [];
    var expandButton = [];
    var i = 0;
    if (this.state.card_count > 4) {
      if (this.state.expandList === true) {
        for (i = 0; i < this.state.card_count; i++) {
          let chosenCard = this.state.imageList[i]
          divisions.push(
            <img className="App-Image-Gallery"
            src={process.env.PUBLIC_URL + '/ProductImages/' + this.props.OS + this.props.folder + chosenCard + '.png'} 
            alt=""
            onError={i => i.target.style.display='none'}
            onClick={() => {this.setState({ clickedImage: chosenCard }); this.ToggleenlargeImageOn()}}/>
          )
        }
        expandButton.push(
          <div className="Toggle-Div">
            <br></br>
            <button className="CollapseButton" onClick={() => {this.ToggleexpandListOff()}}>Collapse</button>
          </div>
        )
      } else {
        for (i = 0; i < 4; i++) {
          let chosenCard = this.state.imageList[i]
          divisions.push(
            <img className="App-Image-Gallery"
            src={process.env.PUBLIC_URL + '/ProductImages/' + this.props.OS + this.props.folder + chosenCard + '.png'} 
            alt=""
            onClick={() => {this.setState({ clickedImage: chosenCard }); this.ToggleenlargeImageOn()}}/>
          )
        }
        expandButton.push(
          <div className="Toggle-Div">
            <br></br>
            <button className="ExpandButton" onClick={() => {this.ToggleexpandListOn()}}>Expand</button>
          </div>
        )
      }
    } else {
      for (i = 0; i < this.state.card_count; i++) {
        let chosenCard = this.state.imageList[i]
        divisions.push(
          <img className="App-Image-Gallery"
          src={process.env.PUBLIC_URL + '/ProductImages/' + this.props.OS + this.props.folder + chosenCard + '.png'} 
          alt=""
          onClick={() => {this.setState({ clickedImage: chosenCard }); this.ToggleenlargeImageOn()}}/>
        )
      }
    }
    
    return (
      <div>
        {!this.state.enlargeImage && (
            <div>
              <div className="App-Image-List">
                {divisions} 
              </div>
              {expandButton}
            </div>
            
          )}
          {this.state.enlargeImage && (
            <div className="App-Image-Enlarged">
             <button className="PreviousImageButton" onClick={() => {this.TogglePreviousImage()}}>{'<'}</button>
            <img className="App-Image-Large"
              src={process.env.PUBLIC_URL + '/ProductImages/' + this.props.OS + this.props.folder + this.state.clickedImage + '.png'} 
              alt=""
              onClick={() => this.ToggleenlargeImageOff()}/>
              <button className="NextImageButton" onClick={() => {this.ToggleNextImage()}}>{'>'}</button>
            </div>
          )}
      </div>
    );
  }
}



export default ImageGallery
