/*THis componenet renders vanilla header with logo and links 

Drop down menu for small screen devices uses this example
https://blog.campvanilla.com/reactjs-dropdown-menus-b6e06ae3a8fe
and is modified
*/

import React from 'react';
import Footer from './Footer'
import Header from './Header'

class PrivacyPolicy extends React.Component {
  constructor() {
    super();

    this.state = {
      showMenu: false,
    };
  }

  render() {
    return (
      <div className="Page-Division">
        <Header/>
        <div className="Page-Inside">
          <p className="PP-Heading">Privacy Policy</p>
          <p className="PP-Text">I am not in the business of doing anything with your data, I never store or transmit your personal details.</p>
          <br></br>
          <p className="PP-SubHeading">Apps:</p>
          <p className="PP-Text">I do not collect any personal data by default, most of my apps don’t event connect to the internet, the only time my Apps connect to the internet is when they are retrieving currency rates or syncing data with iCloud, anything synced to iCloud is securely stored on Apple’s servers and is not accessible by me or anyone else as far as I am aware.</p>
          <br></br>
          <p className="PP-SubHeading">Email:</p>
          <p className="PP-Text">When you send a message to my email your email address is visible on my email list, I only use it to respond to enquiries and to help solve problems, I never share any information I receive with anyone and never use it to send any marketing emails.</p>
          <br></br>
          <p className="PP-SubHeading">Website:</p>
          <p className="PP-Text">I do not collect any data when you visit my website, the only time I receive anything is when you use the contact page to send and enquiry, which I only receive the details you send and use it to get back to your enquiry.</p>
          <br></br>
          <p className="PP-SubHeading">Questions:</p>
          <p className="PP-Text">Please contact me if you have any questions regarding my privacy policy. I will be happy to help.</p>
          </div>
        <Footer/>
      </div>
    );
  }
}



export default PrivacyPolicy
