import React, { Component } from 'react';
import { Helmet } from "react-helmet";

import Home from './components/Home'
import MacOSApps from './components/MacOSApps'
import IOSApps from './components/IOSApps'
import PrivacyPolicy from './components/PrivacyPolicy'
import ContactPage from './components/ContactPage'
import CalcVerter from './components/MacOSApps/CalcVerter'
import HealthCalculator from './components/MacOSApps/HealthCalculator'
import GeometryCalculator from './components/MacOSApps/GeometryCalculator'
import PhysicsCalculator from './components/MacOSApps/PhysicsCalculator'
import FinanceCalculator from './components/MacOSApps/FinanceCalculator'
import EngineeringCalculator from './components/MacOSApps/EngineeringCalculator'
import DynamicsCalculator from './components/MacOSApps/DynamicsCalculator'
import ChemistryCalculator from './components/MacOSApps/ChemistryCalculator'
import FluidMechanicsCalculator from './components/MacOSApps/FluidMechanicsCalculator'
import BaseConverterPro from './components/MacOSApps/BaseConverterPro'
import QuickNotes from './components/MacOSApps/QuickNotes'
import StrongPasswordGenerator from './components/MacOSApps/StrongPasswordGenerator';
import Cipher from './components/MacOSApps/Cipher';
import FallingBlocks from './components/MacOSApps/FallingBlocks';
import ColorCodeGenerator from './components/MacOSApps/ColorCodeGenerator';

import CalcVerterIOS from './components/IOSApps/CalcVerter'
import HealthCalculatorIOS from './components/IOSApps/HealthCalculator'
import GeometryCalculatorIOS from './components/IOSApps/GeometryCalculator'
import PhysicsCalculatorIOS from './components/IOSApps/PhysicsCalculator'
import FinanceCalculatorIOS from './components/IOSApps/FinanceCalculator'
import EngineeringCalculatorIOS from './components/IOSApps/EngineeringCalculator'
import DynamicsCalculatorIOS from './components/IOSApps/DynamicsCalculator'
import ChemistryCalculatorIOS from './components/IOSApps/ChemistryCalculator'
import FluidMechanicsCalculatorIOS from './components/IOSApps/FluidMechanicsCalculator'
import BaseConverterProIOS from './components/IOSApps/BaseConverterPro'
import StrongPasswordGeneratorIOS from './components/IOSApps/StrongPasswordGenerator';
import CipherIOS from './components/IOSApps/Cipher';
import TetrisIOS from './components/IOSApps/Tetris';
import CrosswordDecipherIOS from './components/IOSApps/CrosswordDecipher';
import SpeedyIOS from './components/IOSApps/Speedy';
import Blocks100 from './components/IOSApps/100Blocks';
import ModernSudoku from './components/IOSApps/ModernSudoku';
import ColorCodeGeneratorIOS from './components/IOSApps/ColorCodeGenerator';

import './App.css';

import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom'

/*const Child = ({ match }) => (
  <div className="App">
        <body className="App-body">
        {(() => {
          if (match.params.id === "#home") {
            return (<Home/>)
          } else if (match.params.id === "#macos-apps") {
            return (<MacOSApps/>)
          } else if (match.params.id === "#ios-apps") {
            return (<IOSApps/>)
          } else if (match.params.id === "#privacy-policy") {
            return (<PrivacyPolicy/>)
          } else if (match.params.id === "#contact") {
            return (<ContactPage/>)
          } else if (match.params.id === "#macos-calcverter") {
            return (<CalcVerter/>)
          } else { return (alert(match.params.id))
          }
          })()}
        </body>
      </div>
)
*/

const home = () => (
  <div className="App">
    <body className="App-body">
      {(() => {
        return (<Home/>)
      })()}
    </body>
  </div>
)

const macos = () => (
  <div className="App">
    <body className="App-body">
      {(() => {
        return (<MacOSApps/>)
      })()}
    </body>
  </div>
)

const ios = () => (
  <div className="App">
    <body className="App-body">
      {(() => {
        return (<IOSApps/>)
      })()}
    </body>
  </div>
)

const privacy = () => (
  <div className="App">
    <body className="App-body">
      {(() => {
        return (<PrivacyPolicy/>)
      })()}
    </body>
  </div>
)

const contact = () => (
  <div className="App">
    <body className="App-body">
      {(() => {
        return (<ContactPage/>)
      })()}
    </body>
  </div>
)

const calcverter = () => (
  <div className="App">
    <body className="App-body">
      {(() => {
        return (<CalcVerter/>)
      })()}
    </body>
  </div>
)

const healthcalculator = () => (
  <div className="App">
    <body className="App-body">
      {(() => {
        return (<HealthCalculator/>)
      })()}
    </body>
  </div>
)

const geometrycalculator = () => (
  <div className="App">
    <body className="App-body">
      {(() => {
        return (<GeometryCalculator/>)
      })()}
    </body>
  </div>
)

const physicscalculator = () => (
  <div className="App">
    <body className="App-body">
      {(() => {
        return (<PhysicsCalculator/>)
      })()}
    </body>
  </div>
)

const financecalculator = () => (
  <div className="App">
    <body className="App-body">
      {(() => {
        return (<FinanceCalculator/>)
      })()}
    </body>
  </div>
)

const engineeringcalculator = () => (
  <div className="App">
    <body className="App-body">
      {(() => {
        return (<EngineeringCalculator/>)
      })()}
    </body>
  </div>
)

const dynamicscalculator = () => (
  <div className="App">
    <body className="App-body">
      {(() => {
        return (<DynamicsCalculator/>)
      })()}
    </body>
  </div>
)

const chemistrycalculator = () => (
  <div className="App">
    <body className="App-body">
      {(() => {
        return (<ChemistryCalculator/>)
      })()}
    </body>
  </div>
)

const fluidmechanicscalculator = () => (
  <div className="App">
    <body className="App-body">
      {(() => {
        return (<FluidMechanicsCalculator/>)
      })()}
    </body>
  </div>
)

const baseconverterpro = () => (
  <div className="App">
    <body className="App-body">
      {(() => {
        return (<BaseConverterPro/>)
      })()}
    </body>
  </div>
)

const quicknotes = () => (
  <div className="App">
    <body className="App-body">
      {(() => {
        return (<QuickNotes/>)
      })()}
    </body>
  </div>
)

const strongpasswordgenerator = () => (
  <div className="App">
    <body className="App-body">
      {(() => {
        return (<StrongPasswordGenerator/>)
      })()}
    </body>
  </div>
)

const cipher = () => (
  <div className="App">
    <body className="App-body">
      {(() => {
        return (<Cipher/>)
      })()}
    </body>
  </div>
)

const fallingblocks = () => (
  <div className="App">
    <body className="App-body">
      {(() => {
        return (<FallingBlocks/>)
      })()}
    </body>
  </div>
)

const colorcodegenerator = () => (
  <div className="App">
    <body className="App-body">
      {(() => {
        return (<ColorCodeGenerator/>)
      })()}
    </body>
  </div>
)

const calcverterios = () => (
  <div className="App">
    <body className="App-body">
      {(() => {
        return (<CalcVerterIOS/>)
      })()}
    </body>
  </div>
)

const healthcalculatorios = () => (
  <div className="App">
    <body className="App-body">
      {(() => {
        return (<HealthCalculatorIOS/>)
      })()}
    </body>
  </div>
)

const geometrycalculatorios = () => (
  <div className="App">
    <body className="App-body">
      {(() => {
        return (<GeometryCalculatorIOS/>)
      })()}
    </body>
  </div>
)

const physicscalculatorios = () => (
  <div className="App">
    <body className="App-body">
      {(() => {
        return (<PhysicsCalculatorIOS/>)
      })()}
    </body>
  </div>
)

const financecalculatorios = () => (
  <div className="App">
    <body className="App-body">
      {(() => {
        return (<FinanceCalculatorIOS/>)
      })()}
    </body>
  </div>
)

const engineeringcalculatorios = () => (
  <div className="App">
    <body className="App-body">
      {(() => {
        return (<EngineeringCalculatorIOS/>)
      })()}
    </body>
  </div>
)

const dynamicscalculatorios = () => (
  <div className="App">
    <body className="App-body">
      {(() => {
        return (<DynamicsCalculatorIOS/>)
      })()}
    </body>
  </div>
)

const chemistrycalculatorios = () => (
  <div className="App">
    <body className="App-body">
      {(() => {
        return (<ChemistryCalculatorIOS/>)
      })()}
    </body>
  </div>
)

const fluidmechanicscalculatorios = () => (
  <div className="App">
    <body className="App-body">
      {(() => {
        return (<FluidMechanicsCalculatorIOS/>)
      })()}
    </body>
  </div>
)

const baseconverterproios = () => (
  <div className="App">
    <body className="App-body">
      {(() => {
        return (<BaseConverterProIOS/>)
      })()}
    </body>
  </div>
)

const strongpasswordgeneratorios = () => (
  <div className="App">
    <body className="App-body">
      {(() => {
        return (<StrongPasswordGeneratorIOS/>)
      })()}
    </body>
  </div>
)

const cipherios = () => (
  <div className="App">
    <body className="App-body">
      {(() => {
        return (<CipherIOS/>)
      })()}
    </body>
  </div>
)

const tetrisios = () => (
  <div className="App">
    <body className="App-body">
      {(() => {
        return (<TetrisIOS/>)
      })()}
    </body>
  </div>
)

const crossworddecipherios = () => (
  <div className="App">
    <body className="App-body">
      {(() => {
        return (<CrosswordDecipherIOS/>)
      })()}
    </body>
  </div>
)

const speedyios = () => (
  <div className="App">
    <body className="App-body">
      {(() => {
        return (<SpeedyIOS/>)
      })()}
    </body>
  </div>
)

const blocks100 = () => (
  <div className="App">
    <body className="App-body">
      {(() => {
        return (<Blocks100/>)
      })()}
    </body>
  </div>
)

const modernSudoku = () => (
  <div className="App">
    <body className="App-body">
      {(() => {
        return (<ModernSudoku/>)
      })()}
    </body>
  </div>
)

const colorcodegeneratorios = () => (
  <div className="App">
    <body className="App-body">
      {(() => {
        return (<ColorCodeGeneratorIOS/>)
      })()}
    </body>
  </div>
)

class App extends Component {
  /* Constructor */
  constructor(props) {
    super(props)
  
    /* App state*/
    this.state = {
        current_page: 'home',
    }
  }

  render() {
    let HashRoute = ({ component: Component, path, ...routeProps }) => (
      <Route 
        {...routeProps}
        component={({ location, ...props }) =>
          location.hash === path && <Component {...props} />
        }
      />
    )
    return (
      <div>
        <Helmet>
          <title>Loay Setrak - macOS, iOS, iPadOS and tvOS Apps</title>
          <meta name="description" content="Loay Setrak Software and support site, here you can find all the information you need about Loay Setrak software." />
          <meta name="keywords" cpntent="loay,setrak,app,apps,contact,developer,email,address,calcverter,unit,converter,calculator,all,in,one,web,site,ios,macos,ipados,mac,ipad,iphone,games,tvos,tv" />
        </Helmet>
        <Router>
          <HashRoute path="#home" component={home} />
          <HashRoute path="#macos/apps" component={macos} />
          <HashRoute path="#ios/apps" component={ios} />
          <HashRoute path="#privacy-policy" component={privacy} />
          <HashRoute path="#contact" component={contact} />
          <HashRoute path="#macos/calcverter" component={calcverter} />
          <HashRoute path="#macos/health-calculator" component={healthcalculator} />
          <HashRoute path="#macos/geometry-calculator" component={geometrycalculator} />
          <HashRoute path="#macos/physics-calculator" component={physicscalculator} />
          <HashRoute path="#macos/finance-calculator" component={financecalculator} />
          <HashRoute path="#macos/engineering-calculator" component={engineeringcalculator} />
          <HashRoute path="#macos/dynamics-calculator" component={dynamicscalculator} />
          <HashRoute path="#macos/chemistry-calculator" component={chemistrycalculator} />
          <HashRoute path="#macos/fluid-mechanics-calculator" component={fluidmechanicscalculator} />
          <HashRoute path="#macos/base-converter-pro" component={baseconverterpro} />
          <HashRoute path="#macos/quick-notes" component={quicknotes} />
          <HashRoute path="#macos/strong-password-generator" component={strongpasswordgenerator} />
          <HashRoute path="#macos/cipher" component={cipher} />
          <HashRoute path="#macos/falling-blocks" component={fallingblocks} />
          <HashRoute path="#macos/color-code-generator" component={colorcodegenerator} />

          <HashRoute path="#ios/calcverter" component={calcverterios} />
          <HashRoute path="#ios/health-calculator" component={healthcalculatorios} />
          <HashRoute path="#ios/geometry-calculator" component={geometrycalculatorios} />
          <HashRoute path="#ios/physics-calculator" component={physicscalculatorios} />
          <HashRoute path="#ios/finance-calculator" component={financecalculatorios} />
          <HashRoute path="#ios/engineering-calculator" component={engineeringcalculatorios} />
          <HashRoute path="#ios/dynamics-calculator" component={dynamicscalculatorios} />
          <HashRoute path="#ios/chemistry-calculator" component={chemistrycalculatorios} />
          <HashRoute path="#ios/fluid-mechanics-calculator" component={fluidmechanicscalculatorios} />
          <HashRoute path="#ios/base-converter-pro" component={baseconverterproios} />
          <HashRoute path="#ios/strong-password-generator" component={strongpasswordgeneratorios} />
          <HashRoute path="#ios/cipher" component={cipherios} />
          <HashRoute path="#ios/tetris" component={tetrisios} />
          <HashRoute path="#ios/crossword-decipher" component={crossworddecipherios} />
          <HashRoute path="#ios/speedy" component={speedyios} />
          <HashRoute path="#ios/100-blocks" component={blocks100} />
          <HashRoute path="#ios/modern-sudoku" component={modernSudoku} />
          <HashRoute path="#ios/color-code-generator" component={colorcodegeneratorios} />

          <HashRoute path="" component={home} />
        </Router>
      </div>
    )    
   }
}

/*
<Route exact path="/#" component={Default}/>
        <Route path={process.env.PUBLIC_URL + '#'} component={Default}></Route>
        <Route path="/:id" component={Child}/>
        <Route path={process.env.PUBLIC_URL + '/:id'} component={Child}></Route>
        */
       
export default App;
