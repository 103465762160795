
import React from 'react';
import Footer from '..//Footer'
import Header from '..//Header'
import ImageGallery from '..//ImageGallery'

import {
  BrowserRouter as Router,
} from 'react-router-dom'

import {withRouter} from 'react-router-dom'

class Dynamics extends React.Component {
  constructor() {
    super();

    this.state = {
      routerValue: Router,
      imageList: ["Dynamics-Calculator1","Dynamics-Calculator2","Dynamics-Calculator3","Dynamics-Calculator4"],
    };
  }

  render() {
    return (
      <div className="Page-Division">
        <Header/>
        <div className="Page-Inside-Clear">
          <div className="App-Page-Top">
            <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/macOS/Dynamics-Calculator.png'} alt=""/>
            <p className="App-Page-Title">Dynamics Calculator</p>
          </div>
          <a className="Download-Icon-Link" href="https://itunes.apple.com/app/id1225872584?mt=12">
            <img className="Download-Icon" src={process.env.PUBLIC_URL + '/DownloadBadges/Download_macOS-Black.png'} alt=""/>
          </a>
          <ImageGallery
          imageList = {this.state.imageList}
          OS = {"macOS/"}
          folder = {"Dynamics-Calculator/"}
          />
          <div className="App-Description">
            <p>Dynamics Calculator is an easy to use App that Contains 78 Dynamics Calculators Split into 3 Categories:</p>
            <br></br>
            <p>Basic Dynamics Includes 5 Calculators:</p>
            <ul>
            <li>Young Modulus of Isotropic Elastic Solids</li>
              <li>Terminal Velocity</li>
              <li>Impedance of Forced Oscillation</li>
              <li>Oscillation Period of Double Pendulum</li>
              <li>Mach Wedge Angle for Shock Waves</li>
            </ul>
            <br></br>
            <p>Astrodynamics 24 Calculators:</p>
            <ul>
              <li>Young Modulus of Isotropic Elastic Solids</li>
              <li>Luminosity of Cepheid Variable</li>
              <li>Absolute Visual Magnitude of Cepheid Variables</li>
              <li>Black Hole Evaporation Time</li>
              <li>Black Hole Temperature</li>
              <li>Cosmological Recession Velocity</li>
              <li>Relativistic Pressure</li>
              <li>Neutron Star Degeneracy Pressure by Non-Relativistic Neutrons</li>
              <li>Chandrasekhar Mass</li>
              <li>Angular Radius of Einstein Ring</li>
              <li>Absolute Magnitude of Sun I Band</li>
              <li>Black Hole Schwarzschild Radius</li>
              <li>Black Hole Gravitational Redshift</li>
              <li>Gravitational Wave Luminosity</li>
              <li>Cosmological Lambda Density Parameter</li>
              <li>Hubble Parameter at Redshift</li>
              <li>Hubble Law Radial Velocity</li>
              <li>Friedmann Equation Based on Pressure</li>
              <li>Friedmann Equation</li>
              <li>Eddington Luminosity Limit based on Solar Mass</li>
              <li>Eddington Luminosity Limit</li>
              <li>Cosmological Redshift</li>
              <li>Cosmological Matter Density Parameter</li>
              <li>Cosmological Curvature Density Parameter</li>
              <li>Cosmology Critical Density</li>
            </ul>
            <br></br>
            <p>Thermodynamics Includes 49 Calculators:</p>
            <ul>
              <li>Young Modulus of Isotropic Elastic Solids</li>
              <li>Heat Flow</li>
              <li>Stefan Boltzmann Law - Radiation Energy</li>
              <li>Otto Cycle Compression Ratio (CR)</li>
              <li>Carnot Cycle Efficiency</li>
              <li>Stefan–Boltzmann Law - Radiant Heat Energy</li>
              <li>Heat Transfer Rate</li>
              <li>Thermal Linear and Volumetric Expansion</li>
              <li>Thermal Volumetric Expansion Coefficient</li>
              <li>Thermal Linear Expansion Coefficient</li>
              <li>Thermal Diffusivity</li>
              <li>Thermal Conductivity</li>
              <li>Hall Voltage</li>
              <li>Ehrenfest Equation for Second Order Phase Transition</li>
              <li>Ehrenfest Equation for First Order Phase Transition</li>
              <li>Van der Waals Force (Interaction)</li>
              <li>Log Mean Temperature Difference (LMTD)</li>
              <li>Heat Transfer (Q)</li>
              <li>Flow Coefficient (Cv) for Saturated Wet Steam</li>
              <li>Solar Panel Capacity</li>
              <li>Solar Panel Requirement</li>
              <li>Van der Waals Gas Critical Pressure</li>
              <li>Dieterici Gas Critical Pressure</li>
              <li>Dieterici Gas Reduced Pressure</li>
              <li>Gas Viscosity</li>
              <li>Black Body Radiation Exitance</li>
              <li>Reduced Van der Waals Equation of State</li>
              <li>Critical Molar Volume of Van Der Waals Gas</li>
              <li>Van der Waals Gas Critical Temperature</li>
              <li>Critical Molar Volume of Dieterici Gas</li>
              <li>Mean Free Path</li>
              <li>Dieterici Gas Equation of State Pressure</li>
              <li>Flow Coefficient of Air</li>
              <li>Specific Latent Heat</li>
              <li>Mechanical Advantage</li>
              <li>Monatomic Gas Pressure</li>
              <li>Fermi Energy of Fermi Gas</li>
              <li>Fermi Temperature of Electrons</li>
              <li>Fermi Gas Electron Heat Capacity</li>
              <li>Density of States of Fermi Gas</li>
              <li>Phase Transition Latent Heat</li>
              <li>Clausius Clapeyron Relation</li>
              <li>Phonon Lattice Thermal Conductivity</li>
              <li>Internal Energy of Monatomic Gas</li>
              <li>Flow Coefficient of Air and Gases for Non-Critical Pressure Drop</li>
              <li>Convective Heat Transfer</li>
              <li>Isentropic Flow Pressure Relation Based on Temperature</li>
              <li>Mean Heat Transfer Rate of Heat Exchanger</li>
              <li>Most Probable Speed in Maxwell Boltzmann Distribution</li>
              <li>Kirchhoff's Law Radiative Transfer to Find Source Function</li>
            </ul>
            <br></br>
            <p>Engineering Calculator also includes:</p>
            <ul>
              <li>Formula for Each Calculator</li>
              <li>Description for Each Calculator</li>
              <li>Copy Answer Button</li>
              <li>Clear Button</li>
            </ul>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}



export default withRouter(Dynamics);
