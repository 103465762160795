
import React from 'react';
import Footer from './Footer'
import Header from './Header'

class ContactPage extends React.Component {
  constructor() {
    super();

    this.state = {
      showMenu: false,
    };
  }

  render() {
    return (
      <div className="Page-Division">
        <Header/>
        <div className="Page-Inside">
          <p className="Contact-Title">Get In Touch</p>
          <hr></hr>
          <p className="Contact-Text">Get in touch by sending an email to <a className="Contact-Text-Link" href="mailto:loaysetrak.software@outlook.com.au">loaysetrak.software@outlook.com.au</a></p>
        </div>
        <Footer/>
      </div>
    );
  }
}

export default ContactPage
