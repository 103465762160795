/*THis componenet renders vanilla header with logo and links 

Drop down menu for small screen devices uses this example
https://blog.campvanilla.com/reactjs-dropdown-menus-b6e06ae3a8fe
and is modified
*/

import React from 'react';
import Footer from './Footer'
import Header from './Header'
import HomeImageGallery from './HomeImageGallery'

class Home extends React.Component {
  constructor() {
    super();

    this.state = {
      card_clicked: false,
      cardIndex: 0,
      app_link: '',
      card_count: 0,
      card_count_ios: 0,
    
      products: [
        { id: 'id923932984', name: 'CalcVerter', description: 'Best Unit Converter and Calculator', imageCount: 12, type: 'macOS' },
        { id: 'id1260480179', name: 'Quick Notes', description: 'Notes App and Menu Bar Widget', imageCount: 3, type: 'macOS' },
        { id: 'id1387740382', name: 'Strong Password Generator', description: 'Generate Strong Passwords', imageCount: 3, type: 'macOS' },
        { id: 'id939169935', name: 'Base Converter Pro', description: 'Convert between bases 2 - 64', imageCount: 2, type: 'macOS' },
        { id: 'id1478407640', name: 'Color Code Generator', description: 'Generate and save color codes', imageCount: 4, type: 'macOS' },
        { id: 'id1474614556', name: 'Falling Blocks', description: 'Tile matching puzzle game', imageCount: 2, type: 'macOS' },
        { id: 'id1468668353', name: 'Cipher', description: 'Encrypt and decrypt text', imageCount: 3, type: 'macOS' },
        { id: 'id1104781999', name: 'Physics Calculator', description: '120 Physics Calculators', imageCount: 4, type: 'macOS' },
        { id: 'id1130694845', name: 'Finance Calculator', description: '97 Finance Calculators', imageCount: 4, type: 'macOS' },
        { id: 'id1156440755', name: 'Engineering Calculator', description: '59 Engineering Calculators', imageCount: 4, type: 'macOS' },
        { id: 'id1019029632', name: 'Health Calculator', description: '34 Health Calculators', imageCount: 4, type: 'macOS' },
        { id: 'id1116628390', name: 'Geometry Calculator', description: '30 Geometry Calculators', imageCount: 4, type: 'macOS' },
        { id: 'id1225872584', name: 'Dynamics Calculator', description: '78 Dynamics Calculators', imageCount: 4, type: 'macOS' },
        { id: 'id1149303127', name: 'Chemistry Calculator', description: '35 Chemistry Calculators', imageCount: 4, type: 'macOS' },
        { id: 'id1149304414', name: 'Fluid Mechanics Calculator', description: '90 Fluid Mechanics Calculators', imageCount: 4, type: 'macOS' },
        { id: 'id1006610733', name: 'CalcVerter', description: 'Best Unit Converter and Calculator', imageCount: 27, type: 'iOS' },
        { id: 'id1474442643', name: 'Falling Blocks', description: 'Tile matching puzzle game', imageCount: 6, type: 'iOS' },
        { id: 'id1543529533', name: 'Vault', description: 'Hide photos, videos and more behind a calculator', imageCount: 4, type: 'iOS' },
        { id: 'id1604133932', name: 'Modern Sudoku', description: 'Brain training numbers game', imageCount: 4, type: 'iOS' },
        { id: 'id1468302178', name: 'Strong Password Generator', description: 'Generate and save strong passwords', imageCount: 5, type: 'iOS' },
        { id: 'id1039218498', name: 'Base Converter Pro', description: 'Convert between bases 2 - 64', imageCount: 4, type: 'iOS' },
        { id: 'id1478407640', name: 'Color Code Generator', description: 'Generate and save color codes', imageCount: 4, type: 'iOS' },
        { id: 'id1468669089', name: 'Cipher', description: 'Encrypt and decrypt text', imageCount: 5, type: 'iOS' },
        { id: 'id1297962147', name: 'Physics Calculator', description: '120 Physics Calculators', imageCount: 7, type: 'iOS' },
        { id: 'id1297956111', name: 'Finance Calculator', description: '97 Finance Calculators', imageCount: 8, type: 'iOS' },
        { id: 'id1297957037', name: 'Engineering Calculator', description: '59 Engineering Calculators', imageCount: 7, type: 'iOS' },
        { id: 'id1038950650', name: 'Health Calculator', description: '34 Health Calculators', imageCount: 7, type: 'iOS' },
        { id: 'id1286515380', name: 'Geometry Calculator', description: '30 Geometry Calculators', imageCount: 7, type: 'iOS' },
        { id: 'id1297961471', name: 'Dynamics Calculator', description: '78 Dynamics Calculators', imageCount: 7, type: 'iOS' },
        { id: 'id1297961142', name: 'Chemistry Calculator', description: '35 Chemistry Calculators', imageCount: 7, type: 'iOS' },
        { id: 'id1297964597', name: 'Fluid Mechanics Calculator', description: '90 Fluid Mechanics Calculators', imageCount: 7, type: 'iOS' }
      ],
    };
  }

  componentDidMount() {
    var count = 0
    for (var i = 0; i < this.state.products.length; i++) {
      if (this.state.products[i].type === 'macOS') {
        count += 1
      }
    }

    this.setState({ card_count: count })
    count = 0

    for (i = 0; i < this.state.products.length; i++) {
      if (this.state.products[i].type === 'iOS') {
        count += 1
      }
    }

    this.setState({ card_count_ios: count})
  }
  
  showCard() {
    this.setState({ card_clicked: true})
  }

  closeCard() {
    this.setState({ card_clicked: false})
  }

  render() {
    let products_macOS = this.state.products.filter(product => product.type === 'macOS')
    let products_iOS = this.state.products.filter(product => product.type === 'iOS')
    var divisions_macOS = [];
    var divisions_iOS = [];

    if (!this.state.card_clicked) {
      divisions_macOS = products_macOS.map((product, index) => {
          return (
            <div className="cardBox" onClick={() => {this.setState({ cardIndex: index }); this.setState({app_link: product.id}); this.showCard()}}>
              <div className="cardDivision">
                <div className="horizontalDivision">
                <img className="cardIcon" src={process.env.PUBLIC_URL + '/Icons/macOS/' + product.name.split(' ').join('-') + '.png'} alt=""/>
                <div>
                <p className="cardTitle">{product.name}</p>
                <p className="cardSubtitle">{product.description}</p>
                </div>
                </div>
                <img className="cardImage" src={process.env.PUBLIC_URL + '/ProductImages/macOS/' + product.name.split(' ').join('-') + '.png'} alt=""/>
              </div>
              </div>
          );
      });

      divisions_iOS = products_iOS.map((product, index) => {
          return (
            <div className="cardBox" onClick={() => {this.setState({ cardIndex: products_macOS.length + index }); this.setState({app_link: product.id}); this.showCard()}}>
            <div className="cardDivision">
              <div className="horizontalDivision">
              <img className="cardIcon" src={process.env.PUBLIC_URL + '/Icons/iOS/' + product.name.split(' ').join('-') + '.png'} alt=""/>
              <div>
              <p className="cardTitle">{product.name}</p>
              <p className="cardSubtitle">{product.description}</p>
              </div>
              </div>
              <br></br>
              <div className="cardImagesHorizontal">
              <img className="cardsImage" src={process.env.PUBLIC_URL + '/ProductImages/iOS/' + product.name.split(' ').join('-') + '/' + product.name.split(' ').join('-') + '1.png'} alt=""/>
              <img className="cardsImage" src={process.env.PUBLIC_URL + '/ProductImages/iOS/' + product.name.split(' ').join('-') + '/' + product.name.split(' ').join('-') + '2.png'} alt=""/>
              <img className="cardsImage" src={process.env.PUBLIC_URL + '/ProductImages/iOS/' + product.name.split(' ').join('-') + '/' + product.name.split(' ').join('-') + '3.png'} alt=""/>
              </div>
            </div>
            </div>
          );
      });

    } else {
      let product = this.state.products[this.state.cardIndex]
      var imgArray = [];

      for (var i = 0; i < product.imageCount; i++) {
        imgArray.push(product.name.replace(/\s/g, "-") + (i+1));
      }
      
      if (product.type === 'macOS') {
        divisions_macOS.push(
          <div className="cardDivisionEnlarged">
            <div className="cardEnlargedTop">
              <img className="cardIconEnlarged" src={process.env.PUBLIC_URL + '/Icons/macOS/' + product.name.split(' ').join('-') + '.png'} alt=""/>
              <div className="cardEnlargedTitleDivision">
                <p className="cardTitleEnlarged">{product.name}</p>
                <p className="cardSubtitleEnlarged">{product.description}</p>
              </div>
            <br></br>
            <button className="cardEnlargedClose" onClick={() => this.closeCard()}>X</button>
          </div>
            <HomeImageGallery
            imageList = {imgArray}
            OS = {"macOS/"}
            folder = {product.name.replace(/\s/g, "-") + "/"}
            />
            <div className="App-Links-Home">
              <a className="appLinkHome" href={"https://loaysetrak.github.io/#macos/" + product.name.replace(/\s/g, "-").toLowerCase()}>More Info</a>
              <a className="appLinkHome" href={"https://apps.apple.com/app/" + product.id}>Download Now</a>
            </div>
          </div>
        )
      } else {
        divisions_iOS.push(
          <div className="cardDivisionEnlarged">
            <div className="cardEnlargedTop">
              <img className="cardIconEnlarged" src={process.env.PUBLIC_URL + '/Icons/iOS/' + product.name.split(' ').join('-') + '.png'} alt=""/>
              <div className="cardEnlargedTitleDivision">
                <p className="cardTitleEnlarged">{product.name}</p>
                <p className="cardSubtitleEnlarged">{product.description}</p>
              </div>
            <br></br>
            <button className="cardEnlargedClose" onClick={() => this.closeCard()}>X</button>
          </div>
            <HomeImageGallery
            imageList = {imgArray}
            OS = {"iOS/"}
            folder = {product.name.replace(/\s/g, "-") + "/"}
            />
            <div className="App-Links-Home">
              <a className="appLinkHome" href={"https://loaysetrak.github.io/#ios/" + product.name.replace(/\s/g, "-").toLowerCase()}>More Info</a>
              <a className="appLinkHome" href={"https://apps.apple.com/app/" + product.id}>Download Now</a>
            </div>
          </div>
        )
      }
    }
    
    return (
      <div className="Home-Page">
        <Header/>
        <div className="Profile-Division">
          <div className="Profile-Details">
            <p className="Profile-Heading">LOAY SETRAK</p>
            <p className="Profile-Subheading">Software Engineer</p>
            <br></br>
            <div className="Profile-Links-Division">
            <img className="Profile-Link-Image" src={process.env.PUBLIC_URL + '/SocialMedia/twitter.png'} alt=""/> 
            <a className="App-Link" href="https://twitter.com/loay_setrak">https://twitter.com/loay_setrak</a>
            </div>
            <div className="Profile-Links-Division">
            <img className="Profile-Link-Image" src={process.env.PUBLIC_URL + '/SocialMedia/Outlook.png'} alt=""/> 
            <a className="App-Link" href="mailto:loaysetrak.software@outlook.com.au">loaysetrak.software@outlook.com.au</a>
            </div>
          </div>
          <img className="Profile-Image" src={process.env.PUBLIC_URL + '/Images/profile-image.png'} alt=""/> 
        </div>
        <br></br>
        <br></br>
        <div className="homeDivisionTitleContainer">
        <body className="homeDivisionTitle">macOS Apps</body>
        </div>
        <br></br>
        <div className="Home-Page-Bottom">
        {divisions_macOS}
        </div>
        <br></br>
        <br></br>
        <div className="homeDivisionTitleContainer">
        <body className="homeDivisionTitle">iOS Apps</body>
        </div>
        <br></br>
        <div className="Home-Page-Bottom">
        {divisions_iOS} 
        </div>
        <br></br>
        <Footer/>
      </div>
    );
  }
}

export default Home