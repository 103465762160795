
import React from 'react';
import Footer from '..//Footer'
import Header from '..//Header'
import ImageGallery from '..//ImageGallery'

import {
  BrowserRouter as Router,
} from 'react-router-dom'

import {withRouter} from 'react-router-dom'

class StrongPasswordGenerator extends React.Component {
  constructor() {
    super();

    this.state = {
      routerValue: Router,
      imageList: ["Strong-Password-Generator1","Strong-Password-Generator2","Strong-Password-Generator3"],
    };
  }

  render() {
    return (
      <div className="Page-Division">
        <Header/>
        <div className="Page-Inside-Clear">
          <div className="App-Page-Top">
            <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/macOS/Strong-Password-Generator.png'} alt=""/>
            <p className="App-Page-Title">Strong Password Generator</p>
          </div>
          <a className="Download-Icon-Link" href="https://itunes.apple.com/app/id1387740382?mt=12">
            <img className="Download-Icon" src={process.env.PUBLIC_URL + '/DownloadBadges/Download_macOS-Black.png'} alt=""/>
          </a>
          <ImageGallery
          imageList = {this.state.imageList}
          OS = {"macOS/"}
          folder = {"Strong-Password-Generator/"}
          />
          <div className="App-Description">
            <p>Strong Password generator allow you to easily generate random passwords.</p>
            <p>Passwords can be one or a combination of some or all of the following:</p>
            <ul>
              <li>Numbers</li>
              <li>Symbols</li>
              <li>Lower case letters</li>
              <li>Upper case letters</li>
              <li>Text Alignment options</li>
            </ul>
            <br></br>
            <p>Features:</p>
            <ul>
              <li>Passwords can be from 4 - 64 characters long depending on use inputs</li>
              <li>Similar characters can be excluded (e.g. 1,i,I,l,L,o,O,0)</li>
              <li>Duplicate characters can be excluded</li>
              <li>Includes password strength indicator</li>
              <li>Includes today view widget to generate passwords without opening the app (app settings are shared with the widget)</li>
              <li>Password Generator never keeps or shares any records of generated passwords</li>
              <li>Click the copy button to copy generated password</li>
            </ul>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}



export default withRouter(StrongPasswordGenerator);
