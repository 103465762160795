
import React from 'react';
import Footer from '..//Footer'
import Header from '..//Header'
import ImageGallery from '..//ImageGallery'

import {
  BrowserRouter as Router,
} from 'react-router-dom'

import {withRouter} from 'react-router-dom'

class Cipher extends React.Component {
  constructor() {
    super();

    this.state = {
      routerValue: Router,
      imageList: ["Cipher1","Cipher2","Cipher3","Cipher4","Cipher5"],
      OSName: 'iOS/',
    };
  }

  iOSon() {
    this.setState({ OSName: 'iOS/' })
  }

  iPadOSon() {
    this.setState({ OSName: 'iPadOS/' })
  }

  render() {
    var OSSelectionButton = [];
    if (this.state.OSName === 'iOS/') {
      OSSelectionButton.push(
        <div className="Toggle-Div">
          <button className="ToggleOS" onClick={() => {this.iPadOSon()}}>View iPadOS</button>
        </div>
      )
    } else {
      OSSelectionButton.push(
        <div className="Toggle-Div">
          <button className="ToggleOS" onClick={() => {this.iOSon()}}>View iOS</button>
        </div>
      )
    }

    return (
      <div className="Page-Division">
        <Header/>
        <div className="Page-Inside-Clear">
          <div className="App-Page-Top">
            <img className="App-Icon" src={process.env.PUBLIC_URL + '/Icons/iOS/Cipher.png'} alt=""/>
            <p className="App-Page-Title">Cipher</p>
          </div>
          <a className="Download-Icon-Link" href="https://apps.apple.com/app/cipher/id1468669089">
            <img className="Download-Icon" src={process.env.PUBLIC_URL + '/DownloadBadges/Download_iOS-Black.png'} alt=""/>
          </a>
          <br></br>
          <br></br>
          {OSSelectionButton}
          <ImageGallery
          imageList = {this.state.imageList}
          OS = {this.state.OSName}
          folder = {"Cipher/"}
          />
          <div className="App-Description">
            <p>Cipher is a simple and easy to use App allows you to encrypt and decrypt text.</p>
              <br></br>
              <p>To encrypt text:</p>
              <ol>
                <li>Type in the text you wish to encrypt or tap the paste button</li>
                <li>Select your encryption method</li>
                <li>Tap encrypt button</li>
                <li>Tap the copy button to copy the output</li>
              </ol>
              <p>To decrypt text:</p>
              <ol>
                <li>Type in the text you wish to decrypt or tap the paste button</li>
                <li>Select your encryption method</li>
                <li>Tap decrypt button</li>
                <li>Tap the copy button to copy the output</li>
              </ol>
              <br></br>
              <p>Cipher also includes a keyboard extension that allows you the send encrypted messages to you friends.</p>
              <p>To send a message</p>
              <ol>
                <li>Select your encryption method using the Cipher App</li>
                <li>Enable the keyboard extension</li>
                <li>Toggle on the lock icon on top left corner of the Cipher keyboard</li>
                <li>Start typing and your message will be encrypted as you type</li>
              </ol>
              <p>To read a message using the keyboard</p>
              <ol>
                <li>Copy the message</li>
                <li>Tap the downward arrow on top right corner of the Cipher Keyboard</li>
                <li>The decrypted message will be displayed</li>
              </ol>
              <p>Encryption methods available:</p>
              <ul>
                <li>ROT-X Cipher</li>
                <li>Modified ROT Cipher</li>
                <li>Vigenere Cipher</li>
                <li>Letter Numbers Cipher</li>
              </ul>
              <p>More encryption methods will be added in the future.</p>
          </div>
        </div>
        <Footer/>
      </div>
    );
  }
}



export default withRouter(Cipher);
