/*THis componenet renders vanilla header with logo and links 

Drop down menu for small screen devices uses this example
https://blog.campvanilla.com/reactjs-dropdown-menus-b6e06ae3a8fe
and is modified
*/

import React from 'react';

class HomeImageGallery extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      imageList: this.props.imageList,
      card_count: 0,
      clickedImage: "CalcVerter1",
    };
  }

  componentDidMount() {
    this.setState({ card_count: this.state.imageList.length})
    this.setState({ clickedImage: this.state.imageList[0]})
  }

  ToggleNextImage() {
    let currentIndex = this.state.imageList.indexOf(this.state.clickedImage)
    if (currentIndex < this.state.card_count-1) {
      this.setState({ clickedImage: this.state.imageList[currentIndex+1]})
    } else {
      this.setState({ clickedImage: this.state.imageList[0]})
    }
  }

  TogglePreviousImage() {
    let currentIndex = this.state.imageList.indexOf(this.state.clickedImage)

    if (currentIndex === 0) {
      this.setState({ clickedImage: this.state.imageList[this.state.card_count-1]})
    } else {
      this.setState({ clickedImage: this.state.imageList[currentIndex-1]})
    }
  }

  render() {
    return (
      <div className="App-Image-Enlarged">
        <button className="PreviousImageButtonHome" onClick={() => {this.TogglePreviousImage()}}>{'<'}</button>
        <img className="App-Image-Large-Home"
          src={process.env.PUBLIC_URL + '/ProductImages/' + this.props.OS + this.props.folder + this.state.clickedImage + '.png'} 
          alt=""/>
        <button className="NextImageButtonHome" onClick={() => {this.ToggleNextImage()}}>{'>'}</button>
      </div>
    );
  }
}



export default HomeImageGallery
